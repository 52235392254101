//@import "~bootstrap/scss/_variables.scss";

$body-bg: #fff;
$body-color: #000;

// stylelint-disable value-keyword-case
$font-family-sans-serif:     Calibri !default;
$font-family-base:            $font-family-sans-serif !default;


$primary: #ed6e29 !default;

//Custom variables
$grey-100: #e9ecef;
$grey-500: #979797;

//Nav bar
$navbar-light-color:                #000 !default;
$navbar-light-hover-color:          #000 !default;
$navbar-light-active-color:         $primary !default;
$navbar-light-disabled-color:       #000 !default;
